.page-layout.list-page{
    // background: black!important;
    .center{
        padding: 0 !important;
        .content-card{
            width: 100%;
            border-radius: 0 !important;
            .content{
                .list-page-content{
                    .full-content-with-sidebar{  
                        .full-content-sidebar{
                            width: 220px;
                            position: fixed;
                            z-index:10;
                            // -webkit-box-shadow: 0 9px 8px 0 rgba(0, 0, 0, 0.35);
                            // -moz-box-shadow: 0 9px 8px 0 rgba(0, 0, 0, 0.35);
                            // box-shadow: 0 9px 8px 0 rgba(0, 0, 0, 0.35);
                            .full-sidebar-container{
                                position: relative;
                                .full-sidebar-content{
                                    height: calc(100vh - 148px);
                                    padding: 8px 0;
                                    .mat-expansion-panel{
                                        &.mat-expanded{
                                            .mat-expansion-panel-header{
                                                -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                                -moz-box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
                                                0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
                                                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                                box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
                                                0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
                                                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                            }
                                        }
                                        .mat-expansion-panel-header{
                                            padding: 0 20px 0 16px;
                                            .mat-badge-content{
                                                right: -24px;
                                                top: -8px;
                                                width: 22px;
                                                height: 22px;
                                                line-height: 22px;
                                            }
                                        }

                                        .mat-expansion-panel-body{
                                            padding: 0 0 16px;
                                            
                                        }
                                    }
                                    .mat-pseudo-checkbox-checked::after {
                                        top: 2px;
                                        left: 0px;
                                        width: 10px !important;
                                        height: 3px !important;
                                    }
                                }
                            }                                
                        }

                        .full-content-main{
                            // height: calc(100vh - 284px);
                            width: calc(100% - 220px);
                            position: relative;
                            overflow: hidden !important;
                            left: 220px;
                            .full-content-main-options{
                                position: absolute;
                                height: 128px;
                                padding: 8px 16px 8px 32px;
                                // background-color: #fff;
                                z-index: 9;
                                top: 0;
                                left: 0;
                                right: 0; 
                                // -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                // -moz-box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
                                // 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
                                // 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
                                // 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
                                // 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                .search-and-sort-list{width: 100%;}
                                .search-wrapper{
                                    // width: 20%;
                                    // max-width: 226px;
                                    // min-width: 20%;
                                }
                                .sort-wrapper{
                                    // max-width: 400px;
                                    // width: 30%;
                                    .full-list-sort{
                                        width: 150px;
                                        min-width: 150px;
                                        max-width: 150px;
                                        margin: 0 12px;
                                    }
                                }
                                .filters-wrapper{
                                    width: 100%;
                                    .full-filters-chosen{
                                        // max-height: 88px;
                                        overflow-y: scroll;
                                        overflow-x: hidden;
                                    }
                                    .mat-chip-list-wrapper{
                                        margin: 0 !important; //override of theme -4px margin?

                                        .mat-chip{
                                            font-size: 12px;
                                            padding: 10px 8px 10px 12px;   
                                            min-height: 24px;
                                        }
                                    }            
                                }                                   
                            }
                            .full-content-main-container{
                                position: relative;
                                margin: 128px 0 64px;                                  
                                .full-content-main-content{
                                    //  = 92 (header height) + 128 (interior content search height) +64 (toolbar height) + 64 (bottom footer margin height)
                                    height: calc(100vh - 340px);
                                .mat-list-base{
                                        padding: 8px!important; //override of padding from theme
                                    }
                                    .list-item{
                                        &.list-item-inactive{
                                            
                                        }
                                    }
                                }                                
                            }
                            .full-content-main-buttons{
                                position: absolute;
                                height: 64px;
                                padding: 8px 16px;
                                background-color: #fff;
                                z-index: 9;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                -webkit-box-shadow: 0px 2px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px -2px rgba(0, 0, 0, 0.14), 0px 1px 3px 2px rgba(0, 0, 0, 0.12);
                                -moz-box-shadow:0px 2px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px -2px rgba(0, 0, 0, 0.14), 0px 1px 3px 2px rgba(0, 0, 0, 0.12);
                                box-shadow: 0px 2px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px -2px rgba(0, 0, 0, 0.14), 0px 1px 3px 2px rgba(0, 0, 0, 0.12); 
                            }
                        }
                    }
                }

                &.full-content-without-sidebar{
                    position: relative;
                    // overflow: hidden !important; //important used to override fuse theme
                    // padding: 0 24px;
                    .list-page-content{
                        position: relative;
                        .full-content-main-options{
                            position: absolute;
                            height: 66px;
                            right: 0;
                            left: 0;
                            padding: 0 24px;
                            z-index: 5;
                            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                            .search-and-sort-list{
                                width: 100%;
                                .sort-wrapper{
                                    .full-list-sort{
                                      min-width: 175px;
                                      .mat-form-field-wrapper{
                                        margin-bottom: -1.25em;
                                      }
                                    }
                                }
                            }
                        }
                                                
                        .full-content-main-content{
                            position: relative;
                            top: 66px;
                            bottom: 0;
                            padding: 24px 24px 0;
                            .full-content-main-wrap{
                                // height: calc(100vh - 210px);
                                // FEES LIST
                                .fees-select-year-container{
                                    width: 100%;
                                    margin-bottom: 16px;

                                    .fees-select-year-custom-label{
                                        p{
                                            display: inline-block;
                                            font-weight: 700;
                                            font-size: 16px;
                                            margin: 0 8px 0 0;

                                        }
                                        mat-icon{
                                            color: rgba(0,0,0,0.7);
                                        }
                                        mat-tooltip{

                                        }
                                    }
                                    mat-form-field{
                                        min-width: 200px;
                                    }
                                }
                                .fees-list-wrap{
                                    width: 100%;
                                    .fees-list-item{
                                       min-height: 44px;
                                       height: auto;
                                       &.list-item-inactive{

                                       }
                                        .mat-list-item-content{
                                            padding:0;
                                            .fees-listing{
                                                width: 100%;
                                                // margin-bottom: 16px;
                                                padding:16px 0;
                                                border-bottom: 1px solid rgba(0,0,0,0.1);
                                                .fee-column{
                                                    width: 23%;
                                                    &.fee-column-heading{
                                                        font-weight: 700;
                                                    }
                                                    &.menu-column{
                                                        width: 8%;
                                                        margin-top: -14px;
                                                        text-align: right;
                                                    }
                                                    div:first-child{
                                                        margin-bottom: 8px;
                                                    }
                                                }
                                            }
                                        }
                                        
                                    }
                                }
                            }
                            
                        }
                                            
                        .full-content-main-buttons{}
                    }
                }
           }        
        }
    }
} 