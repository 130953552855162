
@import "./customColors.scss";
// @import "src/app/core/custom/scss/customColors.scss";


body.theme-default .mat-expansion-panel:not([class*=mat-elevation-z]){
    box-shadow: none;
}

.c_caps {
    text-transform: uppercase;
}
.c_text-align-right{
    text-align: right;
}

// .mat-divider,
.mat-divider-vertical{
 width: 2px;
}

.page-layout.tabbed-list-page,
.page-layout.list-page,
.page-layout.form-page{
    .center{
        padding: 0 !important;
        .header.page-header,
        .header.list-page-header,
        .header.form-page-header{
            // height: 92px !important;
            // min-height: 92px !important;
            // max-height: 92px !important;
            height: 84px !important;
            min-height: 84px !important;
            max-height: 84px !important;
            padding: 16px 8px;
            z-index: 10;

        }
    }
}

body.theme-default .page-layout.carded.fullwidth  .center  .content-card {
    border-radius: 8px 8px 0 0;
}
.page-layout.carded.fullwidth.inner-scroll  .center  .content-card {
    flex: 1 1 auto;
}
.page-layout.carded.fullwidth  .center  .content-card {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    overflow: hidden;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

// CUSTOM BEGINS
// widget scss - move to component

.custom--widget {
    height: 325px;
    width: 325px;
    .custom--widget-title-area{
        padding: 0 8px 0 24px;
        .custom--widget-img{
            width: 56px;
        }
        .custom--widget-title-text{
            font-family: 'Oswald', 'Helvetica Neue', 'Arial', sans-serif;
            font-size: 24px;
            font-weight: 300;
            text-transform: uppercase;
        }
    }
    .custom--widget-main{
        padding: 8px 8px 32px 16px;
        .count{
            span{
                display: inline-block;
                
                &.link{
                    cursor: pointer;
                    font-family: 'Helvetica Neue', sans-serif;
                    font-weight: 500;
                    color: #000;
                    text-decoration: underline;
                    font-size: 15px;
        
                    display: inline-block;
                }
                &:first-child{
                    font-size: 20px;
                    font-weight: 700;
                    margin-right: 8px;
                }
                &.total--custom{
                    color:$notification--blue;
                }
                &.new--custom{
                    color:$notification--red;
                }
                &.pending--custom{
                    color:$notification--orange;

                }
                &.approved--custom{
                    color:$notification--green;

                }
            }
        }
        
    }
 
}

//List

.page-list-main{
    .page-list-main-body{
        .list-item--custom{
            border: 1px solid;
            border-color: rgba(0, 0, 0, 0.12);
            border-radius: 8px;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        }
        &.Pending {
            box-shadow: 0px 3px 1px -2px $notification--yellow, 0px 2px 2px 0px $notification--yellow, 0px 1px 5px 0px $notification--yellow;
            background: rgba(0, 0, 0, 0.2);
        }
      
          &.Approved {
            background: white;
          }
      
          &.Denied {
            box-shadow: 0px 3px 1px -2px $notification--red, 0px 2px 2px 0px $notification--red, 0px 1px 5px 0px $notification--red;
            background: gray;

          }
    }
}

body.theme-default{
   #main{
       background: $systemBkgd;
       color: $main-body-font;
   }
}

// DASHBOARD
@mixin themetoolbar($bkgdcolor,$fontcolor) {
    background-color: $bkgdcolor;
    color: $fontcolor;
}


.dashboard-wrapper{
    display: flex;
    flex-direction: column;    
    flex: 1 1 0%;
    width: 100%;
    justify-content: center;
    
    &.superAdmin{
        background-color: $superAdminPrimary;
        color: $superAdminOnPrimary;
    }
    &.exchangeAdmin{
        background-color: $exchangeAdminPrimary;
        color: $exchangeAdminOnPrimary;
    }
    &.provider{
        background-color: $providerPrimary;
        color: $providerOnPrimary;
    }
    &.member{
        background-color: $memberPrimary;
        color: $memberOnPrimary;
    }

    .dashboard-interior{
        display: flex;
        flex-direction: column;    
        flex: 1 1 0%;
        max-width: 1200px;
        padding: 0 15px;
        margin: auto;
        text-align: right;
        .widgets{
            display:flex;
            flex-direction: row;
            flex: 1 1 0%;
            justify-content: center;
            width: 100%;
            .widget{
                color: $systemOnBkgd;
            }
        }
    }
}

// calendar
.course-exchange-calendar {
    background: $exchangeAdminBkgd;
    .header-wrap{
      background-color: $exchangeAdminPrimary;
      color: $exchangeAdminOnPrimary;
    }
    .course-calendar{
        .add-new-btn{
            background: $exchangeAdminPrimary;
            color: $exchangeAdminOnPrimary; 
        }
    }
  }

  
  #memberCalendar .member-calendar-scene .semester-accordion-panel .add-new-btn{
    display: none !important;
}

.member-calendar{
    elb-page-header{
        background: $memberPrimary;
        .h2{
            color: $memberOnPrimary;
        }  
    }
    .course-calendar{
        .add-new-btn{
            background: $memberPrimary;
            color: $memberOnPrimary; 
        }
    }
}
.provider-calendar{
    elb-page-header{
        background: $providerPrimary;
        .h2{
            color: $providerOnPrimary;
        }  
    }
    .course-calendar{
        .add-new-btn{
            background: $providerPrimary;
            color: $providerOnPrimary; 
        }
    }
}

//billing
.billing-page{
    width: 100%;

    &.provider-page  {
        .header{
        color: $providerOnPrimary;
        background-color: $providerPrimary;}
    }
    &.member-page{
        .header{
            background-color:$memberPrimary;
            color: $memberOnPrimary;
        }
    }
    &.course-exchange-page{
        .header{
            background-color:$exchangeAdminPrimary;
            color: $exchangeAdminOnPrimary;
        }
    }
    .header-title-container {
        width: 100%;
        max-width: 1200px;
        width: 1200px;
        margin: auto;
        // place-content: center flex-start !important;
    }
}

//overwrite of course calendar center padding
.page-layout.carded.fullwidth {
    &.course-calendar-layout{
        .center{
            padding: 0;
            .inner-content{
                elb-page-header{
                    width: 100%;
                    height: 58px;
                    display: flex;
                .header-wrap{
                    @media screen and (min-width: 1400px){
                        .inner-calendar-content{
                          width: 1400px;
                          margin: 0 auto;
                         
                        }
                      }
                      @media screen and (max-width: 1399px){
                        .inner-calendar-content{
                          width: 80%;
                          margin: 0 auto;
                        }
                      }
                }
                .academic-year-select {
                    .mat-form-field {
                      width: 400px;
                    }
                    .mat-form-field-appearance-outline .mat-form-field-infix,
                    .mat-form-field-infix{
                      padding: 5px  0;
                    }
                }
            }
        }
        
    }

}

}

// MEMBER PAGES
//general
.member-page{
    .top-bg{background: $memberPrimary;}
    elb-page-header{background: $memberPrimary;}
}

.course-schedule-component.page-layout {
    .center{
        elb-page-header{
          .header{
            &.page-header{
            position: absolute;
            width: 100%;
            top: 0;
            right: 0;
            left: 0;
            height: 58px !important;
            min-height: 58px !important;
            max-height: 58px !important;
            display: flex;
            vertical-align: middle;
            padding: 0;
          }
        }
      }
    }
}