.page-layout.tabbed-list-page{
    
    .center{
        padding: 0 !important;
        .content-card{
            width: 100%;
            border-radius: 0 !important;

            .content{

                // 1/3 because 3 of them.
                mat-tab-header{
                    .mat-tab-label{
                        width: 33.3%;
                    }
                }
                mat-tab-body{

                    .tab-content{
                        position: relative;
                        
                        &.tabbed-list-content{
                            overflow: hidden !important;
                            // this may be applicable across to all tabs with sidebar...
                        }
                        
                        .tab-content-with-sidebar{
                            
                            .tab-content-sidebar{
                                width: 225px;
                                position: fixed;
                                z-index: 999;
                                box-shadow: 3px 2px 2px 0 rgba(0, 0, 0, 0.2);
                                -webkit-box-shadow: 3px 2px 2px 0 rgba(0, 0, 0, 0.2);
                                -moz-box-shadow:3px 2px 2px 0 rgba(0, 0, 0, 0.2);

                                .tab-sidebar-container{
                                    position: relative;
                                    .tab-sidebar-content{
                                        //(212=64+64+84)  (toolbar+tabs+header heights)
                                        height: calc(100vh - 212px);
                                        padding: 8px 0;
                                        .mat-expansion-panel{
                                            &.mat-expanded{
                                                .mat-expansion-panel-header{
                                                    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                                    -moz-box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
                                                    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
                                                    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                                    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
                                                    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
                                                    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                                }
                                            }
                                            .mat-expansion-panel-header{
                                                padding: 0 20px 0 16px;
                                                .mat-badge-content{
                                                    right: -24px;
                                                    top: -8px;
                                                    width: 22px;
                                                    height: 22px;
                                                    line-height: 22px;
                                                }
                                            }

                                            .mat-expansion-panel-body{
                                                padding: 0 0 16px;
                                                
                                            }
                                        }
                                        .mat-pseudo-checkbox-checked::after {
                                            top: 2px;
                                            left: 0px;
                                            width: 10px !important;
                                            height: 3px !important;
                                        }
                                    }
                                }
                                 
                            }
                            
                            .tab-content-main{
                                // height: calc(100vh - 284px);
                                width: calc(100% - 225px);
                                position: relative;
                                overflow: hidden !important;
                                left: 225px;
                                .tab-content-main-options{
                                    position: absolute;
                                    height: 128px;
                                    padding: 8px 16px 8px 32px;
                                    background-color: #fff;
                                    z-index: 9;
                                    top: 0;
                                    left: 0;
                                    right: 0; 
                                    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                    -moz-box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
                                    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
                                    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
                                    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
                                    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                    .search-and-sort-list{width: 100%;}
                                    .search-wrapper{
                                        // width: 20%;
                                        // max-width: 226px;
                                        // min-width: 20%;
                                    }
                                    .sort-wrapper{
                                        // max-width: 400px;
                                        // width: 30%;
                                        .tab-list-sort{
                                            width: 150px;
                                            min-width: 150px;
                                            max-width: 150px;
                                            margin: 0 12px;
                                        }
                                    }
                                    .filters-wrapper{
                                        width: 100%;
                                        .tab-filters-chosen{
                                        // max-height: 68px;
                                        overflow-y: scroll;
                                        overflow-x: hidden;
                                        }
                                        .mat-chip-list-wrapper{
                                            margin: 0 !important; //override of theme -4px margin?
                                        }             
                                    }                                   
                                }
                                .tab-content-main-container{
                                    position: relative;
                                    margin: 128px 0 64px;
                                    
                                       
                                        .tab-content-main-content{
                                            height: calc(100vh - 404px);
                                  
                                 
                                            .mat-list-base{
                                                padding: 8px!important; //override of padding from theme
                                            }
                                        }
                                    
                                    
                                    
                                }
                                .tab-content-main-buttons{
                                    position: absolute;
                                    height: 64px;
                                    padding: 8px 16px;
                                    background-color: #fff;
                                    z-index: 9;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    -webkit-box-shadow: 0px 2px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px -2px rgba(0, 0, 0, 0.14), 0px 1px 3px 2px rgba(0, 0, 0, 0.12);
                                    -moz-box-shadow:0px 2px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px -2px rgba(0, 0, 0, 0.14), 0px 1px 3px 2px rgba(0, 0, 0, 0.12);
                                    box-shadow: 0px 2px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px -2px rgba(0, 0, 0, 0.14), 0px 1px 3px 2px rgba(0, 0, 0, 0.12); 
                                }
                            }
                        }
                    }
                }
            }        
        }
    }
} 