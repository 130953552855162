// Super Admin Dashboard
// Top Bar Background Color:
$super-admin-dashboard-bkg-1: #3b3c3d;
//Side Nav Background Color:

$super-admin-dashboard-bkg-2: #1e1e1f
;
$super-admin-dashboard-accent-1:#557A95;
$super-admin-dashboard-accent-2:  #95C93D;
$super-admin-dashboard-accent-2-disable:  rgba($super-admin-dashboard-accent-1, .5);
$super-admin-dashboard-font-light: #ececec;
$super-admin-dashboard-font-dark: #0c0c0c;
$super-admin-dashboard-font-accent: #23233F;

$super-admin-font-dark:#3b3c3d;
$super-admin-font-medium: #58595b;
$super-admin-font-light: #757679;


// Exchange Admin Dashboard
$exchange-admin-dashboard-bkg-1: #713dc9;
$exchange-admin-dashboard-bkg-2: #F8FDFE;
$exchange-admin-dashboard-accent-1: #713dc9;
$exchange-admin-dashboard-accent-2:  #95C93D;
$exchange-admin-dashboard-accent-2-disable:  rgba($exchange-admin-dashboard-accent-1, .5);

$exchange-admin-dashboard-font-light:  #F8FDFE;
$exchange-admin-dashboard-font-dark:  #713dc9;
$exchange-admin-dashboard-font-accent: #b73dc9;
$exchange-admin-dashboard-font-accent-2: #95C93D;


//TEXT COLORS:
$exchange-admin-font-dark:#3b3c3d;
$exchange-admin-font-medium: #58595b;
$exchange-admin-font-light: #757679;

//BACKGROUND DARK:




// VERSION 2
// $exchange-admin-dashboard-bkg-1: #D3D3D3;
// $exchange-admin-dashboard-bkg-2: #fff;
// $exchange-admin-dashboard-accent-1: #161616;
// $exchange-admin-dashboard-accent-2:  #7C0B2B;
// $exchange-admin-dashboard-accent-2-disable:  rgba($exchange-admin-dashboard-accent-2, .5);

// $exchange-admin-dashboard-font-light:  #000;
// $exchange-admin-dashboard-font-dark:  #F8FDFE;
// $exchange-admin-dashboard-font-accent-1: #000335;
// $exchange-admin-dashboard-font-accent-2: #7C0B2B;



//Admin Provider Dashboard
$admin-provider-dashboard-bkg-1: #404042;
$admin-provider-dashboard-bkg-2: #F7F9FF;
$admin-provider-dashboard-accent-1: #404042;
$admin-provider-dashboard-accent-2:  #5C7C25;
$admin-provider-dashboard-accent-2-disable:  rgba($admin-provider-dashboard-accent-1, .5);
$admin-provider-dashboard-font-light: #F1F2F1;
$admin-provider-dashboard-font-dark:  #181919;
$admin-provider-dashboard-font-accent:
#5C7C25;

//Admin Member Dashboard
$admin-member-dashboard-bkg-1:  #005952;
$admin-member-dashboard-bkg-2: #F8FDFE;
$admin-member-dashboard-accent-1: #005952;
$admin-member-dashboard-accent-2:   rgb(0, 217, 255);
$admin-member-dashboard-accent-2-disable:  rgba($admin-member-dashboard-accent-1, .5);
$admin-member-dashboard-font-light:  #F8FDFE;
$admin-member-dashboard-font-dark:  #050505;
$admin-member-dashboard-font-accent:  rgb(0, 217, 255);



//Institution Admin Dashboard
$institution-admin-dashboard-bkg-1: #3d95c9;
$institution-admin-dashboard-bkg-2: #F8FDFE;
$institution-admin-dashboard-accent-1: #3d95c9;
$institution-admin-dashboard-accent-2:  #95C93D;
$institution-admin-dashboard-accent-2-disable:  rgba($institution-admin-dashboard-accent-1, .5);

$institution-admin-dashboard-font-light:  #F8FDFE;
$institution-admin-dashboard-font-dark:  #000;
$institution-admin-dashboard-font-accent:  #3d95c9;


$notification--green: #138925;
$notification--red: #E01616;
$notification--yellow: #B78900; ///CANNOT BE USED FOR TEXT
$notification--blue: #07288c ;
$notification--orange: #CC5500;

$super-admin--primary-dark-color:#000454;
$super-admin--secondary-dark-color:#BF4900;

$super-admin--accent-light-color: #7FB4FF;
$super-admin--light-text-white:#ffffff;
$super-admin--dark-text-black:#0C0C0C;


$super-admin-primary-light-bkg:#FFFFFF;
$super-admin-secondary-light-bkg:#F2F2F2;

$white: #fff;

//new colors
// links//backgrounds
$member-admin--accent: #077B92;
$member-admin--dark-font:#333;
$member-admin--light-font:#fff;
//used for header banner
// $member-admin--exterior-bg:#ebebeb;
$member-admin--exterior-font: #333;
$member-admin--exterior-bg:#ffffff;


// //used for interior card
$member-admin--nav-bg:#f1f3f4;
$member-admin--interior-bg:#e0e0e0;
//sidebar
$member-admin--white-bg:#f4f4f4;

// NEW TOOLBAR
//Super Admin
$toolbar--SuperAdmin:#060a14;
$lighttoolbaraccent1--SuperAdmin: #a8adba; 
$darktoolbaraccent--SuperAdmin:#292b33;
// Course Exchange Admin 
$toolbar--CourseExchangeAdmin: #c4c4c4;
$lighttoolbaraccent1--CourseExchangeAdmin: #a0a0a0; //center icon color, outline for dropdown and arrow
$darktoolbaraccent--CourseExchangeAdmin:#464646;//main toolbar font color, right icon color 
$lighttoolbaraccent2--CourseExchangeAdmin:#e8e8e8;
//Provider
$toolbar--Provider: #2a2a2a;
$lighttoolbaraccent--Provider: #f5f5f5; 
$darktoolbaraccent--Provider:#737373;
//Member
$toolbar--Member: #f1f3f6;
$lighttoolbaraccent--Member: #a0a0a0; 
$darktoolbaraccent--Member:#464646;


//general layout
$body-bkgd:#F0F2F5;
$main-body-font: #1c1e21;
//white components
//list pages
$font-color-primary: #343434;
$font-color-secondary: #767676;
$light-font-color:#a0a0a0; 

$systemPrimary:#1a4c8f;
$systemPrimaryV:#0d2545;
$systemSecondary:#81283c;
$systemSecondaryV:#672030;
$systemBkgd:#fff; 
//fees list container
$systemSurface:#fff;
$systemError:#B30300;
$systemSuccess:#138925;
//font colors
$systemOnPrimary:#fff;
$systemOnSecondary:#fff;
$systemOnBkgd:#000;
$systemOnSurface:#000;
$systemOnError:#fff;

$superAdminPrimary:#545869;
$superAdminPrimaryV:#363843;
$superAdminSecondary:#1a4c8f;
$superAdminSecondaryV:#0d2545;
$superAdminBkgd:#fff;
$superAdminSurface:#fff;
$superAdminError:#B30300;
//font colors
$superAdminOnPrimary:#fff;
$superAdminOnSecondary:#fff;
$superAdminOnBkgd:#000;
$superAdminOnSurface:#000;
$superAdminOnError:#fff;
// EXCHANGE
$exchangeAdminPrimary:#464646;
$exchangeAdminPrimaryV:#2B2B2B;
$exchangeAdminSecondary:#A6A49D;
$exchangeAdminSecondaryV:#76736B;
$exchangeAdminBkgd:#fff;
$exchangeAdminSurface:#fff;
$exchangeAdminError:#B30300;
//font colors
$exchangeAdminOnPrimary:#fff;
$exchangeAdminOnSecondary:#000000;
$exchangeAdminOnBkgd:#000;
$exchangeAdminOnSurface:#000;
$exchangeAdminOnError:#fff;
// PROVIDER
$providerPrimary:#919191;
$providerPrimaryV:#525252;
$providerSecondary:#242424;
$providerSecondaryV:#121212;
$providerBkgd:#fff;
$providerSurface:#fff;
$providerError:#B30300;

//font colors
$providerOnPrimary:#000000;
$providerOnSecondary:#fff;
$providerOnBkgd:#000;
$providerOnSurface:#000;
$providerOnError:#fff;

$memberPrimary:#1E2526;
$memberPrimaryV:#121617;
$memberSecondary:#81283c;
$memberSecondaryV:#672030;
$memberBkgd:#fff;
$memberSurface:#fff;
$memberError:#B30300;
//font colors
$memberOnPrimary:#fff;
$memberOnSecondary:#000;
$memberOnBkgd:#000;
$memberOnSurface:#fff;
$memberOnError:#ffffff;