.content-form-page{
    position: relative;
    overflow: hidden;

    .inner-form-content-container{
        padding: 20px 20px;
        .inner-form-component{
            form.content-form{
                width: 100%;
                height: calc(100vh - 220px);
                &.two-col-form{
                    .form-column{
                        width: 50%;
                    }
                }
            }
        }

        .content-nav-container{
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: blue;
            height: 64px;
            .content-nav-buttons{
                button.form-button{
                }
            }
        }
    }
    
}