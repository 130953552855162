@use '@angular/material' as mat;
@import "src/app/core/custom/scss/customColors.scss";

$logged-out-accent: $systemPrimary;
$logged-out-light-text: #fff;
$white-bg: #fff;

.account-form-page {
    width: 100%;
    background: url('/assets/images/backgrounds/brickbkg.jpg') no-repeat;
    background-size: cover;

    .account-form-wrapper {
        flex: 1 0 auto;
        padding: 32px;

        @include media-breakpoint('xs') {
            padding: 16px;
        }

        .account-form-container {
            width: 384px;
            max-width: 384px;
            padding: 32px;
            text-align: center;
            background-color: $white-bg;
            @include mat.elevation(16);

            @include media-breakpoint('xs') {
                padding: 24px;
                width: 100%;
            }

            .logo {
                width: 256px;
                margin: 16px auto;
            }

            .title {
                font-size: 20px;
                margin: 0 0 32px 0;
                text-transform: uppercase;
            }
            .account-form-link{
                color: $logged-out-accent;
            }
            form {
                width: 100%;
                text-align: left;

                mat-form-field {
                    width: 100%;
                }

                mat-checkbox {
                    margin: 0;
                }

                .remember-forgot-password {
                    font-size: 13px;
                    margin-top: 8px;

                    .remember-me {
                        margin-bottom: 16px
                    }

                    .forgot-password {
                        font-size: 13px;
                        font-weight: 600;
                        margin-bottom: 16px
                    }
                }

                .submit-button {
                    width: 220px;
                    margin: 16px auto;
                    display: block;
                    background: $logged-out-accent;
                    color: $logged-out-light-text;

                    @include media-breakpoint('xs') {
                        width: 90%;
                    }
                }
            }

            .register {
                margin: 32px auto 24px auto;
                font-weight: 600;

                .text {
                    margin-right: 8px;
                }
            }

            .separator {
                font-size: 15px;
                font-weight: 600;
                margin: 24px auto;
                position: relative;
                overflow: hidden;
                width: 100px;

                .text {
                    display: inline-flex;
                    position: relative;
                    padding: 0 8px;
                    z-index: 9999;

                    &:before,
                    &:after {
                        content: '';
                        display: block;
                        width: 30px;
                        position: absolute;
                        top: 10px;
                        border-top: 1px solid;
                    }

                    &:before {
                        right: 100%;
                    }

                    &:after {
                        left: 100%;
                    }
                }
            }

            button {

                &.google,
                &.facebook {
                    width: 192px;
                    text-transform: none;
                    color: $logged-out-light-text;
                    font-size: 13px;
                }

                @include media-breakpoint('xs') {
                    width: 80%;
                }

                &.google {
                    background-color: #D73D32;
                    margin-bottom: 8px;
                }

                &.facebook {
                    background-color: rgb(63, 92, 154);
                }
            }
        }
    }
}